import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import parse from 'html-react-parser';
import { Box, Grid, Typography } from '@mui/material';
import { Button } from '@doit/pcnt-react-ui-library';
import Loading from '../components/commons/Loading';
import { getStepBiometricData, saveBiometricDataResult } from '../api/onboardingService';
import Toast from '../components/commons/Toast';
import i18n from '../common/i18n';
import errorVerificationImg from '../assets/imgs/error-verification.svg';
import step1Img from '../assets/imgs/icon4id_dni.svg';
import step2Img from '../assets/imgs/icon4id_detalle-dni.svg';
import step3Img from '../assets/imgs/icon4id_cara-despejada.svg';
import '../assets/css/onboardingProcessBiometricResult.css';
import { CREDICUOTAS_WEBSITE_URL, getEnv } from '../api/env';
import { isMobileOperatingSystem } from '../utils/functionsUtil';
import { routes } from '../constants/routes';
import { loadWebchat } from '../components/webchatComponentScript';
import { useUserData } from '../contexts/userDataContext';
import { loanRedirect } from '../components/LoanRequestRedirectService';
import { ERROR_CODES } from '../utils/errorCodeConstant';
import { redirectToContinueURLSlashM } from '../utils/commonServices';

const OnboardingProcessBiometricResult = () => {
  const queryParams = new URLSearchParams(useLocation().search);
  const navigate = useNavigate();

  // Contexto para guardar en memoria si debe o no ofrecer plan B
  const { setUserData } = useUserData();

  const [loading, setLoading] = useState(true);
  const [loanRequest, setLoanRequest] = useState();
  const [showNoHit, setShowNoHit] = useState(false);
  const [showRetry, setShowRetry] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const cleanQueryParams = () => {
    queryParams.delete('decision');
    queryParams.delete('idtx');
    queryParams.delete('externalID');
    queryParams.delete('step');
    queryParams.delete('externaltrxid');
  };

  const goToUploadDocuments = (hash) => {
    cleanQueryParams();
    queryParams.append('e', '1');
    navigate({
      pathname: `${routes.OnboardingDocumentsLoad}/${hash}`,
      search: `?${queryParams.toString()}`,
    });
  };

  const onHit = (lr) => {
    cleanQueryParams();
    loanRedirect(lr, navigate, queryParams.toString());
  };

  const onNoHit = () => {
    setShowNoHit(true);
  };

  const onErrRnp = (lr) => {
    cleanQueryParams();
    loanRedirect(lr, navigate, queryParams.toString());
  };

  const handleRetry = () => {
    goToUploadDocuments(loanRequest?.hashKey);
  };

  const processBiometricResult = async () => {
    try {
      const idtx = queryParams.get('idtx') || queryParams.get('auth_id') || 'enrollment';
      const externalID = queryParams.get('externalID') || queryParams.get('hash');

      const res = await saveBiometricDataResult(externalID, idtx);

      const hash = res.data.referenceId;
      setLoanRequest(res.data.loanRequest);

      const { decision } = res.data;
      if (decision === 'HIT') {
        onHit(res.data.loanRequest);
      } else if (decision === 'NO_HIT') {
        onNoHit();
      } else if (decision === 'ERR_RNP') {
        onErrRnp(res.data.loanRequest);
      } else if (decision === 'NOT_FOUND') {
        setUserData({ planB: res.data.selfieOn });
        goToUploadDocuments(hash);
      }
    } catch (error) {
      if (error?.response?.data?.errorCode === ERROR_CODES.BIOMETRIC_DATA_ALREADY_LOADED) {
        const hash = error.response.data.validationErrors[0].message;
        redirectToContinueURLSlashM(hash);
      } else {
        setErrorMessage(i18n.OnboardingProcessBiometricResult.error);
      }
    } finally {
      setLoading(false);
    }
  };

  const processBiometricStep = async () => {
    try {
      const externaltrxid = queryParams.get('externaltrxid');
      const res = await getStepBiometricData(externaltrxid);

      const hash = res.data.referenceId;
      setLoanRequest(res.data.loanRequest);

      const step = queryParams.get('step');
      if (step === '445') {
        // dispositivo no compatible
        navigate({
          pathname: `${routes.OnboardingIncompatibleBrowser}/${hash}`,
          search: `?${queryParams.toString()}`,
        });
      } else if (res.data.selfieOn) {
        // Plan B
        setUserData({ planB: true });
        goToUploadDocuments(hash);
      } else {
        // Reintentar biometria
        setShowRetry(true);
        // Webchat solo se muestra cuando tiene que reintentar la biometria
        loadWebchat(res.data.loanRequest);
      }
    } catch (error) {
      setErrorMessage(i18n.OnboardingProcessBiometricResult.error);
    } finally {
      setLoading(false);
    }
  };

  const isBNPL = () => loanRequest?.productToPay?.ecommerceCartType;

  const goToSite = () => {
    window.location.href = getEnv(CREDICUOTAS_WEBSITE_URL);
  };

  const goToStore = () => {
    window.location.href = loanRequest?.productToPay?.product[0]?.productAttributes?.merchantReturnSuccessURL;
  };

  const init = () => {
    const idtx = queryParams.get('idtx');
    const step = queryParams.get('step');
    const hash = queryParams.get('hash');

    if (idtx || hash) {
      processBiometricResult();
    } else if (step) {
      processBiometricStep();
    }
  };

  useEffect(() => { init(); }, []);

  return (
    <Box className="main-container-body" mb={5}>
      <Grid container className="pre-onboarding-main-container" display="flex" justifyContent="center">
        <Grid item xs={11}>
          { loading && <Loading /> }
          {/* MENSAJE DE NO_HIT */}
          {showNoHit && (
            <>
              <img src={errorVerificationImg} alt="Error en la verificación" width="300px" />
              <Typography className="onboarding-process-biometric-result-title">
                {i18n.OnboardingProcessBiometricResult.notHitTitle}
              </Typography>
              <Typography className="onboarding-process-biometric-result-description">
                {i18n.OnboardingProcessBiometricResult.notHitDescription}
              </Typography>
              <Grid container>
                <Grid item xs={12} md={4}>
                  <img src={step1Img} alt="DNI" width="120px" />
                  <Typography className="onboarding-process-biometric-result-steps">
                    {parse(i18n.OnboardingProcessBiometricResult.steps[0])}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <img src={step2Img} alt="DNI" width="120px" />
                  <Typography className="onboarding-process-biometric-result-steps">
                    {parse(i18n.OnboardingProcessBiometricResult.steps[1])}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <img src={step3Img} alt="DNI" width="120px" />
                  <Typography className="onboarding-process-biometric-result-steps">
                    {parse(i18n.OnboardingProcessBiometricResult.steps[2])}
                  </Typography>
                </Grid>
              </Grid>
              {isBNPL() ? (
                <Box mt={3}>
                  <Button
                    variant="primary"
                    className="btn-primary"
                    fullWidth={isMobileOperatingSystem()}
                    onClick={goToStore}
                    loading={loading}
                  >
                    {i18n.OnboardingProcessBiometricResult.notHitBNPLCTA}
                  </Button>
                </Box>
              ) : (
                <Box mt={3}>
                  <Button
                    variant="primary"
                    className="btn-primary"
                    fullWidth={isMobileOperatingSystem()}
                    onClick={goToSite}
                    loading={loading}
                  >
                    {i18n.OnboardingProcessBiometricResult.notHitCTA}
                  </Button>
                </Box>
              )}
            </>
          )}
          {/* MENSAJE PARA REINTENTAR BIOMETRIA */}
          {showRetry && (
            <>
              <img src={errorVerificationImg} alt="Error en la verificación" width="300px" />
              <Typography className="onboarding-process-biometric-result-title">
                {i18n.OnboardingProcessBiometricResult.retryTitle}
              </Typography>
              <Typography className="onboarding-process-biometric-result-description">
                {i18n.OnboardingProcessBiometricResult.retryDescription}
              </Typography>
              <Box mt={3}>
                <Button
                  variant="primary"
                  className="btn-primary"
                  fullWidth={isMobileOperatingSystem()}
                  onClick={handleRetry}
                  loading={loading}
                >
                  {i18n.OnboardingProcessBiometricResult.retryCTA}
                </Button>
              </Box>
            </>
          )}
          {errorMessage && (
            <Toast
              messageOnError={errorMessage}
              open
              onClose={() => setErrorMessage(undefined)}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default OnboardingProcessBiometricResult;
